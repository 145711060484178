import { FormDataPatch, FormDataPost, Get, Post, getAPIUrl } from "./apiMethod";
import { Authendpoint, Globalendpoints } from "./global";

export const userRegister = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Register, param);
  return Post(url, data, false);
};
export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(url, data, false);
};
export const OtpVerify = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.OtpVerify, param);
  return Post(url, data, false);
};

export const ResendOtp = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.resendOtp, param);
  return Post(url, data, false);
};
export const ForgotPassword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.forgotPassword, param);
  return Post(url, data, false);
};
export const ForgotPasswordOtpVerify = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.forgotPasswordOtpVerify, param);
  return Post(url, data, false);
};
export const ResetPassword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.resetPassword, param);
  return Post(url, data, false);
};
export const walletBal = (param = "") => {
  const url = getAPIUrl(Globalendpoints.walletBalance, param);
  return Get(url);
};
export const getProfile = (param = "") => {
  const url = getAPIUrl(Globalendpoints.profile, param);
  return Get(url);
};
export const updateProfile = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateProfile, param);
  return FormDataPost(url, data);
};
export const GetRoomDetail = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getRoom, param);
  return Get(url);
};
export const MakeBet = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.betting, param);
  return Post(url, data);
};
export const MyBets = (param = "") => {
  const url = getAPIUrl(Globalendpoints.myBets, param);
  return Get(url);
};
export const AllTransactions = (param = "") => {
  const url = getAPIUrl(Globalendpoints.allTransaction, param);
  return Get(url);
};
export const AddFundReq = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.AddFundReq, param);
  return FormDataPost(url, data);
};
export const GetFundReq = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fundReq, param);
  return Get(url);
};
export const AddWithdrawReq = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.AddWithdraReq, param);
  return FormDataPost(url, data);
};
export const getWithdrawreq = (param = "") => {
  const url = getAPIUrl(Globalendpoints.withdrawReq, param);
  return Get(url);
};
export const getWinningHistory = (param = "") => {
  const url = getAPIUrl(Globalendpoints.winningHistory, param);
  return Get(url);
};
export const getBetHistory = (param = "") => {
  const url = getAPIUrl(Globalendpoints.betHistory, param);
  return Get(url);
};
export const getBankDetail = (param = "") => {
  const url = getAPIUrl(Globalendpoints.bankDetail, param);
  return Get(url);
};
