import React, { useEffect, useRef, useState } from "react";
import { IoArrowBack, IoCloudUploadOutline } from "react-icons/io5";
import { Images } from "../../Assets/Index";
import { Button, Form, Image, Input, Upload } from "antd";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { AddFundReq, getBankDetail } from "../../ApiServices/Apifun";
import { dispatchtoast } from "../../Utils";
import { useNavigate } from "react-router-dom";

const AddFund = () => {
  const formref = useRef();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState([]);
  const [adminBank, setAdminBank] = useState({});
  const [imageData, setImageData] = useState(null);
  const getAdminBank = () => {
    getBankDetail()
      .then((res) => {
        if (res.status) {
          setAdminBank(res.data?.bank_detail);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleCustomReq = async ({ file, onSuccess, onError }) => {
    try {
      const dataUrl = await readFileAsDataUrl(file);
      const newFile = {
        ...file,
        name: file.name,
        thumbUrl: dataUrl,
      };
      setImage([newFile]);
      setImageData(file);
      onSuccess();
    } catch (error) {
      console.error(error);
      onError(error);
    }
  };
  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleAdd = (val) => {
    const formdata = new FormData();
    formdata.append("amount", val.amount);
    formdata.append("image", imageData);
    AddFundReq(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          formref.current.setFieldsValue({
            amount: "",
            image: "",
          });
          setImageData("");
          setImage([]);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAdminBank();
  }, []);

  return (
    <div className="flex justify-center mt-4 pb-20 items-center ">
      <div className="w-full">
        <img
          src={Images.Logo}
          className="w-[100px] flex justify-center items-center my-2 m-auto"
          alt="logo"
        />
        <div className="flex justify-start bg-purple px-8  py-2 mt-4 rounded-lg gap-x-3 items-center">
          <div className="text-black font-semibold gap-x-4 flex justify-center items-center text-xs">
            <IoArrowBack
              onClick={() => navigate(-1)}
              className="cursor-pointer"
              size={20}
            />
            <p className="text-lg">Add Fund</p>
          </div>
        </div>
        <div>
          <div className="flex justify-around text-white items-center mt-4">
            <p className="w-52 font-bold">Account Number:</p>
            <p className="text-sm font-medium text-start border-b border-white w-full py-3">
              {adminBank?.ac_no ?? "-----"}
            </p>
          </div>
          <div className="flex justify-around text-white items-center mt-4">
            <p className="w-52 font-bold">IFSC Code:</p>
            <p className="text-sm font-medium text-start border-b border-white w-full py-3">
              {adminBank?.ifsc_code ?? "-----"}
            </p>
          </div>
          <div className="flex justify-around text-white items-center mt-4">
            <p className="w-52 font-bold">Bank Name:</p>
            <p className="text-sm font-medium text-start border-b border-white w-full py-3">
              {adminBank?.bank ?? "-----"}
            </p>
          </div>
          <div className="flex justify-around text-white items-center mt-4">
            <p className="w-52 font-bold">Account Holder Name:</p>
            <p className="text-sm font-medium text-start border-b border-white w-full py-3">
              {adminBank?.ac_holder_name ?? "-----"}
            </p>
          </div>
          <div className="flex justify-around text-white items-center mt-4">
            <p className="w-52 font-bold">UPI I'd:</p>
            <p className="text-sm font-medium text-start border-b border-white w-full py-3">
              {adminBank?.upi_id ?? "-----"}
            </p>
          </div>
          <div className="flex justify-around text-white items-center mt-4">
            <p className="w-52 font-bold">QR CODE:</p>
              <Image
                style={{ height: "80px" }}
                onError={(err) => {
                  err.target.src = Images.NoImage;
                  err.target.alt = "No Image";
                }}
                width={200}
                src={adminBank.image_path}
                alt="itemImage"
                crossOrigin="anonymous"
                className=" object-contain h-20 w-full m-auto rounded-lg"
              />
          </div>
        </div>
        <div>
          <Form
            onFinish={(val) => {
              handleAdd(val);
            }}
            onFinishFailed={(err) => console.log(err)}
            ref={formref}
            form={form}
          >
            <div className="grid mt-4 grid-cols-1 md:grid-cols-2 place-items-start md:place-items-center">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Fill amount field.",
                  },
                ]}
                name={"amount"}
                className="w-full m-0"
              >
                <Input
                  onInput={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    e.target.value = value;
                  }}
                  placeholder="Amount"
                  className="m-0 rounded-none"
                  prefix={<FaIndianRupeeSign />}
                />
              </Form.Item>
              <div className="flex justify-center items-center mt-4 md:mt-0 gap-4">
                <p
                  onClick={() =>
                    formref.current.setFieldsValue({ amount: 1000 })
                  }
                  className="bg-white text-black p-2 rounded-full cursor-pointer hover:bg-darkPurple transition-all  font-semibold"
                >
                  1000
                </p>
                <p
                  onClick={() =>
                    formref.current.setFieldsValue({ amount: 2000 })
                  }
                  className="bg-white text-black p-2 rounded-full cursor-pointer hover:bg-darkPurple transition-all  font-semibold"
                >
                  2000
                </p>
                <p
                  onClick={() =>
                    formref.current.setFieldsValue({ amount: 3000 })
                  }
                  className="bg-white text-black p-2 rounded-full cursor-pointer hover:bg-darkPurple transition-all  font-semibold"
                >
                  3000
                </p>
                <p
                  onClick={() =>
                    formref.current.setFieldsValue({ amount: 4000 })
                  }
                  className="bg-white text-black p-2 rounded-full cursor-pointer hover:bg-darkPurple transition-all  font-semibold"
                >
                  4000
                </p>
              </div>
            </div>
            <Form.Item
              name={`image`}
              label={<p className="text-white">attachment</p>}
              className="w-full"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Upload one payment proof!",
                },
              ]}
            >
              <Upload
                maxCount={1}
                onRemove={() => {
                  setImage([]);
                  formref.current.setFieldsValue({
                    image: "",
                  });
                }}
                listType="picture"
                accept=".jpg, .jpeg, .png"
                fileList={image}
                customRequest={handleCustomReq}
              >
                <Button
                  className="bg-purple text-black border-none font-medium"
                  icon={<IoCloudUploadOutline />}
                >
                  Click to Upload
                </Button>
              </Upload>
            </Form.Item>
            <div className="flex justify-between items-start mt-4">
              {/* <div className="bg-green-700 text-black px-8 py-2 rounded-lg font-semibold cursor-pointer">
                <p>Save</p>
              </div> */}
              <div>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    className="bg-blue-700 w-full text-black hover:border-none border-none outline-none hover:outline-none"
                  >
                    Add Instant
                  </Button>
                </Form.Item>
                {/* <div className="bg-blue-700 text-center mt-4 text-black px-8 py-2 rounded-lg font-semibold cursor-pointer">
                  <p>Add Payment PayU</p>
                </div> */}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddFund;
