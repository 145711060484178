import React from "react";
import { Images } from "../../Assets/Index";
import { FaWhatsapp } from "react-icons/fa6";

const Support = () => {
  return (
    <div className="flex text-white justify-center mt-4 pb-20 items-center ">
      <div className="w-full">
        <img
          src={Images.Logo}
          className="w-[100px] flex justify-center items-center my-2 m-auto"
          alt="logo"
        />
        <div className="flex justify-center bg-purple px-8  py-1 mt-4 rounded-lg gap-x-3 items-center">
          <div>
            <FaWhatsapp size={50} color="#06df52" />
          </div>
        </div>
        <div className="mt-2">
          <p className="font-bold text-xl">Read Rules*</p>
          <p className="mt-2">
            3 minutes 1 issue, 2 minutes and 30 seconds to order, 30 seconds to
            show the lottery result. it opens all day. the total number of trade
            is 480 issues
          </p>
          <p className="mt-4">
            If you spend 100 to trade, after deducting 2% service fee, your
            contract amount is 98:
          </p>
        </div>
        <div>
          <p className="mt-2 text-sm font-medium">
            1. JOIN GREEN: if the result shows 1,3,7,9 you will get (98x2) 196.
            If the result shows 5, you will get (98x1.5) 147.
          </p>
          <p className="mt-2 text-sm font-medium">
            1. JOIN GREEN: if the result shows 1,3,7,9 you will get (98x2) 196.
            If the result shows 5, you will get (98x1.5) 147.
          </p>
          <p className="mt-2 text-sm font-medium">
            1. JOIN GREEN: if the result shows 1,3,7,9 you will get (98x2) 196.
            If the result shows 5, you will get (98x1.5) 147.
          </p>
          <p className="mt-2 text-sm font-medium">
            1. JOIN GREEN: if the result shows 1,3,7,9 you will get (98x2) 196.
            If the result shows 5, you will get (98x1.5) 147.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Support;
