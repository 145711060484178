import React, { useState } from "react";
import { Images } from "../../Assets/Index";
import { Button, Form, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ResetPassword } from "../../ApiServices/Apifun";
import { dispatchtoast, setLocalStorageItem } from "../../Utils";

const ResetPasswordPage = () => {
  const location = useLocation();
  const navigate=useNavigate()
  const { token, mobile } = location.state || {};
  const[loading,setLoading]=useState(false)
  const handleSubmit = (val) => {
    setLoading(true)
    ResetPassword({ ...val, mobile:mobile, token: token })
      .then((res) => {
        if(res.status){
         dispatchtoast(res.message)
          navigate("/")
        }
      })
      .catch((err) => console.log(err)).finally(()=>setLoading(false));
  };
  return (
    <>
      <div className="flex  h-screen justify-center items-center">
        <div className=" flex relative shadow-lg mx-8 shadow-gray-500 rounded-lg justify-center items-center flex-col px-10 md:px-20 py-20">
          <img src={Images.Logo} className="w-[100px]" alt="logo" />
          <p className="mt-2 text-black font-bold">Color Game Prediction</p>
          <div>
            <Form
              onFinish={(val) => handleSubmit(val)}
              onFinishFailed={(err) => console.log(err)}
            >
              <Form.Item
                label={<p className="text-white">Password</p>}
                name={"password"}
                className="m-0"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter new password",
                  },
                ]}
              >
                <Input
                  className="rounded-none bg-white"
                  placeholder="Enter New Password"
                />
              </Form.Item>
              <Form.Item
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter mobile number",
                  },
                ]}
              >
                <Button loading={loading} htmlType="submit" className="w-full mt-2 font-semibold border-none outline-none hover:border-none hover:outline-none bg-green-600 text-white">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="w-24 h-24 animate-pulse bg-green-600 absolute top-0 right-0 rounded-bl-full"></div>
          <div className="w-24 h-24 bg-red-600 animate-pulse absolute bottom-0 left-0 rounded-tr-full"></div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordPage;
