import React, { useRef, useState } from "react";
import { IoArrowBack, IoCloudUploadOutline } from "react-icons/io5";
import { Images } from "../../Assets/Index";
import { Button, Form, Input, Upload } from "antd";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { AddFundReq, AddWithdrawReq } from "../../ApiServices/Apifun";
import { dispatchtoast } from "../../Utils";
import { useNavigate } from "react-router-dom";

const Addwithdrawal = () => {
  const formref = useRef();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleAdd = (val) => {
    const formdata = new FormData();
    formdata.append("amount", val.amount);
    AddWithdrawReq(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          formref.current.setFieldsValue({
            amount: "",
          });
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="flex justify-center mt-4 pb-20 items-center ">
      <div className="w-full">
        <img
          src={Images.Logo}
          className="w-[100px] flex justify-center items-center my-2 m-auto"
          alt="logo"
        />
        <div className="flex justify-start bg-purple px-8  py-2 mt-4 rounded-lg gap-x-3 items-center">
          <div className="text-black font-semibold gap-x-4 flex justify-center items-center text-xs">
            <IoArrowBack
              onClick={() => navigate(-1)}
              className="cursor-pointer"
              size={20}
            />
            <p className="text-lg">Add Withdrawal Request</p>
          </div>
        </div>
        {/* <div>
          <div className="flex justify-around items-center mt-4">
            <p className="w-52 font-bold">Account Number:</p>
            <p className="text-sm font-medium border-b border-purple py-3">
              217401000006403
            </p>
          </div>
          <div className="flex justify-around items-center mt-4">
            <p className="w-52 font-bold">IFSC Code:</p>
            <p className="text-sm font-medium border-b border-purple py-3">
              217401000006403
            </p>
          </div>
          <div className="flex justify-around items-center mt-4">
            <p className="w-52 font-bold">Bank Name:</p>
            <p className="text-sm font-medium border-b border-purple py-3">
              217401000006403
            </p>
          </div>
          <div className="flex justify-around items-center mt-4">
            <p className="w-52 font-bold">Account Holder Name:</p>
            <p className="text-sm font-medium border-b border-purple py-3">
              217401000006403
            </p>
          </div>
          <div className="flex justify-around items-center mt-4">
            <p className="w-52 font-bold">UPI I'd:</p>
            <p className="text-sm font-medium border-b border-purple py-3">
              217401000006403
            </p>
          </div>
        </div> */}
        <div>
          <Form
            onFinish={(val) => {
              handleAdd(val);
            }}
            onFinishFailed={(err) => console.log(err)}
            ref={formref}
            form={form}
          >
            <div className="grid mt-4 grid-cols-1 sm:grid-cols-2 place-items-center">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Fill amount field.",
                  },
                ]}
                name={"amount"}
                className="w-full m-0"
              >
                <Input
                  onInput={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    e.target.value = value;
                  }}
                  placeholder="Amount"
                  className="m-0 rounded-none"
                  prefix={<FaIndianRupeeSign />}
                />
              </Form.Item>
              <div className="flex justify-start mt-4 sm:mt-0 items-center gap-4">
                <p
                  onClick={() =>
                    formref.current.setFieldsValue({ amount: 1000 })
                  }
                  className="bg-purple p-2 rounded-full cursor-pointer hover:bg-darkPurple transition-all text-black font-semibold"
                >
                  1000
                </p>
                <p
                  onClick={() =>
                    formref.current.setFieldsValue({ amount: 2000 })
                  }
                  className="bg-purple p-2 rounded-full cursor-pointer hover:bg-darkPurple transition-all text-black font-semibold"
                >
                  2000
                </p>
                <p
                  onClick={() =>
                    formref.current.setFieldsValue({ amount: 3000 })
                  }
                  className="bg-purple p-2 rounded-full cursor-pointer hover:bg-darkPurple transition-all text-black font-semibold"
                >
                  3000
                </p>
                <p
                  onClick={() =>
                    formref.current.setFieldsValue({ amount: 4000 })
                  }
                  className="bg-purple p-2 rounded-full cursor-pointer hover:bg-darkPurple transition-all text-black font-semibold"
                >
                  4000
                </p>
              </div>
            </div>
            <div className="flex justify-between items-start mt-4">
              <div>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    className="bg-blue-700 w-full text-black hover:border-none border-none outline-none hover:outline-none"
                  >
                    Add Withdrawal Request
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Addwithdrawal;
