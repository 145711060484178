import React, { useEffect, useState } from "react";
import { Images } from "../../Assets/Index";
import Commontable from "../../Components/Commontable";
import { AllTransactions } from "../../ApiServices/Apifun";
import { Button } from "antd";
import { dispatchtoast } from "../../Utils";
import { useSelector } from "react-redux";

const WalletTransaction = () => {
  const [myTransactions, setMyTransactions] = useState([]);
  const walletBalance = useSelector((state) => state.colorGame.walletBal);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const getData = () => {
    AllTransactions(`?limit=10&page=${page}`)
      .then((res) => {
        if (res.status && res.data.data.length!==0) {
          setMyTransactions([
            ...myTransactions,
            ...res.data.data.map((i, index) => {
              return {
                txn_type: <p className="capitalize">{i.transaction_type}</p>,
                amount: <p>{i.amount}</p>,
                Amount_type: <p className="capitalize">{i.amountType}</p>,
                result: <p>{i.formatedDate}</p>,
              };
            }),
          ]);
        }else{
            dispatchtoast("No Data Available!",true)
        }
      })
      .catch((err) => console.log(err)).finally(()=>setLoading(false))
  };
  const handleLoad = () => {
    setLoading(true)
    setPage((prev) => prev + 1);
  };
  useEffect(() => {
    getData();
  }, [page]);
  return (
    <>
      <div className="flex justify-center mt-4 pb-20 items-center ">
        <div className="w-full">
          <img
            src={Images.Logo}
            className="w-[100px] flex justify-center items-center my-2 m-auto"
            alt="logo"
          />
          <div className="flex justify-end bg-purple px-8  py-1 mt-4 rounded-lg gap-x-3 items-center">
            <div className="text-black font-semibold flex-col justify-center items-center text-xs">
              <p>Wallet</p>
              <img src={Images.Money} className="w-8 m-auto my-2" alt="money" />
            </div>
            <p className="text-black font-semibold">Rs. {walletBalance}</p>
          </div>
          <div className="mt-4">
            <Commontable
              rows={myTransactions}
              gridNum={4}
              column={["Type", "Amount", "Type", "Date"]}
            />
          </div>
          <div className="mt-4 flex justify-center items-center ">
            <Button
              onClick={handleLoad}
              loading={loading}
              className="bg-darkPurple text-black hover:border-none border-none outline-none hover:outline-none"
            >
              Load More
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletTransaction;
