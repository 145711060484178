import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../Assets/Index";
import { IoArrowBack, IoCloudUploadOutline } from "react-icons/io5";
import { Button, Form, Input, Skeleton, Upload } from "antd";
import { getProfile, updateProfile } from "../../ApiServices/Apifun";
import { dispatchtoast, getLocalStorageItem } from "../../Utils";
import { APIURL } from "../../ApiServices/Axios";
import { BiSolidPencil } from "react-icons/bi";

const Profile = () => {
  const formref = useRef();
  const fileInputRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [bankDetail, setBankdetail] = useState(null);
  const [image, setImage] = useState([]);
  const [imageData, setImageData] = useState(null);
const[userImage,setUserImage]=useState(null)
  const handleCustomReq = async ({ file, onSuccess, onError }) => {
    try {
      const dataUrl = await readFileAsDataUrl(file);
      const newFile = {
        ...file,
        name: file.name,
        thumbUrl: dataUrl,
      };
      setImage([newFile]);
      setImageData(file);
      onSuccess();
    } catch (error) {
      console.error(error);
      onError(error);
    }
  };
  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const getUser = () => {
    getProfile()
      .then((res) => {
        if (res.status) {
          const { user, bankDetail } = res.data;
          formref.current.setFieldsValue({
            name: user.name,
            mobile: user.mobile,
            email: user.email,
            aadhar_no: user.aadhar_no,
            ac_holder_name: bankDetail?.ac_holder_name??"",
            ac_no: bankDetail?.ac_no??"",
            bank: bankDetail?.bank??"",
            ifsc_code: bankDetail?.ifsc_code??"",
          });
          setUserImage(user)
          setImage([{name: "AAdhar Image",crossOrigin:"anonymous",
            thumbUrl: `${APIURL}/${user.aadhar_image}`}])
          setBankdetail(res.data.bankDetail);
          setUserDetail(user);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUser();
  }, []);
  const handleUpdate = (val) => {
    setLoading(true);
    const formdata = new FormData();
    delete val.aadhar_image;
    Object.keys(val).map((key) => formdata.append(key, val[key]));
    formdata.append("aadhar_image", imageData);
    updateProfile(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = () => {
    // setSpinner(true);
    const formdata = new FormData();
    const file = fileInputRef.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        // setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      formdata.append("image", file);
      // UpdateProfileImage(formdata)
      //   .then((response) => {
      //     if (response.status) {
      //       getProfileDetail();
      //       dispatchtoast("Image Updated Successfully");
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   })
      //   .finally(() => setSpinner(false));
    }
  };
  return (
    <>
      <div className="flex text-black justify-center mt-4 pb-20 items-center ">
        <div className="w-full">
          <img
            src={Images.Logo}
            className="w-[100px] flex justify-center items-center my-2 m-auto"
            alt="logo"
          />
          <div className="flex justify-start bg-purple px-8  py-2 mt-4 rounded-lg gap-x-3 items-center">
            <div className="text-black font-semibold gap-x-4 flex justify-center items-center text-xs">
              <IoArrowBack size={20} />
              <p className="text-lg">Profile</p>
            </div>
          </div>
          <div className="flex justify-end items-center mt-2">
            <p className="text-darkPurple text-xl font-bold">
              Referral Code:{" "}
              <span className="text-lg text-red-500">
                {userDetail?.referral_code??"-"}
              </span>
            </p>
          </div>
          <div className="flex-col mt-2 flex justify-center w-full items-center">
            <img src={Images.redwheel} alt="profile" />
            <p className="font-semibold text-white capitalize text-lg mt-2">{userDetail?.name??"-"}</p>
          </div>
             {/* <div className="user_image flex-col mt-2 flex justify-center m-auto w-1/4 items-center relative">
              {userDetail?.image ? (
                <img
                  crossOrigin="anonymous"
                  src={userDetail.image}
                  //  onClick={() => setOpenImageModal(true)}
                  alt="userImg"
                  className="rounded-full relative border-4 border-primary w-24 h-24"
                />
              ) : (
                <Skeleton.Avatar active={true} size={100} shape={"circle"} />
              )}
              
              <div className="absolute bottom-10 right-0">
                <input
                  type="file"
                  accept="image/*"
                  onChange={() => {
                    handleImageChange();
                    fileInputRef.current.value = "";
                  }}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <BiSolidPencil
                  size={30}
                  onClick={handleClick}
                  className="text-black bg-primary rounded-full  p-1 cursor-pointer"
                />
              </div>
            <p className="font-semibold capitalize text-lg mt-2">{userDetail?.name}</p>

            </div> */}
          <div className="mt-4">
            <Form
              onFinishFailed={(err) => console.log(err)}
              onFinish={(val) => handleUpdate(val)}
              ref={formref}
              form={form}
            >
              <div className="grid grid-cols-2 gap-x-5 place-items-center">
                <Form.Item
                  label="Name"
                  name={"name"}
                  className="w-full"
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter your name"
                    className="rounded-none"
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  initialValue={getLocalStorageItem("userDetail")?.email}
                  name={"email"}
                  className="w-full"
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter your email"
                    disabled
                    className="rounded-none"
                  />
                </Form.Item>
                <Form.Item
                  label="Mobile"
                  initialValue={getLocalStorageItem("userDetail")?.mobile}
                  name={"mobile"}
                  className="w-full"
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter your mobile"
                    disabled
                    className="rounded-none"
                  />
                </Form.Item>
                <Form.Item
                  label="Aadhar Number"
                  name={"aadhar_no"}
                  className="w-full"
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter Aadhar No"
                    className="rounded-none"
                  />
                </Form.Item>
                <Form.Item
                  label="Account Number"
                  name={"ac_no"}
                  className="w-full"
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter Acc Number"
                    className="rounded-none"
                  />
                </Form.Item>
                <Form.Item
                  label="IFSC Code"
                  name={"ifsc_code"}
                  className="w-full"
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter IFSC Code"
                    className="rounded-none"
                  />
                </Form.Item>
                <Form.Item
                  label="Ac Holder Name"
                  name={"ac_holder_name"}
                  className="w-full"
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter Ac holder name"
                    className="rounded-none"
                  />
                </Form.Item>
                <Form.Item
                  label="Bank"
                  name={"bank"}
                  className="w-full"
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter bank name"
                    className="rounded-none"
                  />
                </Form.Item>
                <Form.Item
                  label={<p className="text-white">Aadhar Image</p>}
                  name={"aadhar_image"}
                  className="w-full"
                  labelCol={{ span: 24 }}
                >
                  <Upload
                    maxCount={1}
                    onRemove={() => {
                      setImage([]);
                      setImageData(null)
                      formref.current.setFieldsValue({
                        image: "",
                      });
                    }}
                    listType="picture"
                    accept=".jpg, .jpeg, .png"
                    fileList={image}
                    customRequest={handleCustomReq}
                  >
                    <Button
                      className="bg-purple text-black border-none font-medium"
                      icon={<IoCloudUploadOutline color="black"/>}
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </Form.Item>
                {/* {userDetail?.aadhar_image?<div>
                  <img
                    alt="aadhar image"
                    crossOrigin="anonymous"
                    src={`${APIURL}/${userDetail.aadhar_image}`}
                  />
                </div>:""} */}
              </div>
              <Form.Item className="w-full" labelCol={{ span: 24 }}>
                <Button
                  htmlType="submit"
                  className="w-full mt-2 font-semibold border-none outline-none hover:border-none hover:outline-none bg-green-600 text-black"
                >
                  Update Profile
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
