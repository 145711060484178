import React from "react";

const Commontable = ({ column, rows,gridNum=4 }) => {
  return (
    <>
      <div className="flex gap-x-3 font-semibold text-black justify-between items-center">
        {column.map((i, index) => (
          <div
            key={index}
            className="bg-darkPurple w-full text-center px-6 py-2 rounded-md"
          >
            <p className="text-xs">{i}</p>
          </div>
        ))}
      </div>
      <div style={{
        gridTemplateColumns: `repeat(${gridNum}, minmax(0, 1fr))`
      }} className={`grid  place-items-center gap-x-3 font-semibold text-black`}>
        {rows?.map((i, index) =>
          Object.keys(i).map((key,index) => (
            <div
              key={index}
              className=" w-full text-white flex justify-center items-center px-6 py-2 rounded-md"
            >
              {i[key]}
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Commontable;
