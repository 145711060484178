import { Modal } from "antd";
import React from "react";
import { GiLaurelsTrophy } from "react-icons/gi";
import { Images } from "../../Assets/Index";
import { getColor } from "../../Utils";

const ResultModal = ({ num, open, setIsOpen }) => {
  return (
    <Modal
      open={open}
      onCancel={() => setIsOpen({ show: false, num: null })}
      footer={null}
    >
      <div className="flex justify-center items-center">
        <GiLaurelsTrophy size={100} color="yellow" />
      </div>
      <p className="text-center text-xl font-bold italic">Betting Result</p>
      <img
        className="flex justify-center items-center m-auto w-16 mt-2 text-xl font-bold italic"
        src={Images[getColor(num)?.color]}
      />
      <p className="text-center mt-2 text-xl font-bold">{num}</p>
    </Modal>
  );
};

export default ResultModal;
