import React, { useState } from "react";
import { Images } from "../../Assets/Index";
import { Button, Form, Input, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ForgotPassword,
  ForgotPasswordOtpVerify,
} from "../../ApiServices/Apifun";
import { dispatchtoast } from "../../Utils";

const Otp = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [verifyToken, setVerifytoken] = useState(null);
  const [pageLoader, setpageLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (val) => {
    if (!verifyToken) {
      setLoading(true);
      ForgotPassword({ mobile: val.mobile })
        .then((res) => {
          if (res.status) {
            setVerifytoken(res.verificationToken);
            dispatchtoast(res.message);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      ForgotPasswordOtpVerify({
        ...val,
        mobile: val.mobile,
        verificationToken: verifyToken,
      })
        .then((res) => {
          if (res.status) {
            dispatchtoast(res.message);
            navigate("/reset-password", {
              state: {
                token: res.password_token,
                mobile: val.mobile,
              },
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };
  const handleResend = () => {
    if (form.getFieldValue("mobile")) {
      setpageLoader(true);
      ForgotPassword({ mobile: form.getFieldValue("mobile") })
        .then((res) => {
          if (res.status) {
            dispatchtoast(res.message);
            setVerifytoken(res.verificationToken);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setpageLoader(false));
    } else {
      dispatchtoast("Please enter mobile number", true);
    }
  };
  return (
    <>
      <Spin spinning={pageLoader}>
        <div className="flex  h-screen justify-center items-center">
          <div className=" flex relative shadow-lg mx-8 shadow-gray-500 rounded-lg justify-center items-center flex-col px-10 md:px-20 py-20">
            <img src={Images.Logo} className="w-[100px]" alt="logo" />
            <p className="mt-2 text-black font-bold">Color Game Prediction</p>
            <div>
              <Form
                form={form}
                onFinish={(val) => handleSubmit(val)}
                onFinishFailed={(err) => console.log(err)}
              >
                <Form.Item
                  label={<p className="text-white">Mobile</p>}
                  name={"mobile"}
                  className="m-0"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile number",
                    },
                  ]}
                >
                  <Input
                    className="rounded-none bg-white"
                    placeholder="Enter Mobile"
                  />
                </Form.Item>
                {verifyToken && (
                  <Form.Item
                    label={<p className="text-white">OTP</p>}
                    name={"otp"}
                    className="m-0"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter otp",
                      },
                    ]}
                  >
                    <Input
                      className="rounded-none bg-white"
                      placeholder="Enter OTP"
                    />
                  </Form.Item>
                )}
                <div className="flex text-white justify-end items-center mt-2">
                  <p
                    onClick={handleResend}
                    className="cursor-pointer font-semibold"
                  >
                    Resend Otp
                  </p>
                </div>
                <Form.Item
                  className="w-full"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile number",
                    },
                  ]}
                >
                  <Button
                    loading={loading}
                    htmlType="submit"
                    className="w-full mt-2 font-semibold border-none outline-none hover:border-none hover:outline-none bg-green-600 text-white"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="w-24 h-24 animate-pulse bg-green-600 absolute top-0 right-0 rounded-bl-full"></div>
            <div className="w-24 h-24 bg-red-600 animate-pulse absolute bottom-0 left-0 rounded-tr-full"></div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default Otp;
