import React, { useState } from "react";
import { Images } from "../../Assets/Index";
import { Button, Form, Input, Spin } from "antd";
import { ForgotPassword, userLogin } from "../../ApiServices/Apifun";
import { NavLink, useNavigate } from "react-router-dom";
import { dispatchtoast, setLocalStorageItem } from "../../Utils";

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const handleLogin = (val) => {
    setLoading(true);
    userLogin(val)
      .then((res) => {
        if (res.status) {
          setLocalStorageItem("isLoggedIn", true);
          setLocalStorageItem("accessToken", res.access_token);
          setLocalStorageItem("refreshToken", res.referesh_token);
          setLocalStorageItem("userDetail", res.user);

          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const handleForgot = () => {
    navigate("/otp");
  };
  return (
    <>
      <Spin spinning={pageLoader}>
        <div className="flex  h-screen justify-center items-center">
          <div className=" flex relative shadow-lg mx-8 shadow-gray-500 rounded-lg justify-center items-center flex-col px-10 md:px-20 py-20">
            <img src={Images.Logo} className="w-[100px]" alt="logo" />
            <p className="mt-2 text-black font-bold">Color Game Prediction</p>
            <div>
              <Form
                form={form}
                onFinish={(val) => handleLogin(val)}
                onFinishFailed={(err) => console.log(err)}
              >
                <Form.Item
                  label={<p className="text-white">Mobile</p>}
                  name={"mobile"}
                  className="m-0"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile number",
                    },
                  ]}
                >
                  <Input
                    className="rounded-none bg-white"
                    placeholder="Enter Number"
                  />
                </Form.Item>
                <Form.Item
                  label={<p className="text-white">Password</p>}
                  name={"password"}
                  className="m-0"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter password",
                    },
                  ]}
                >
                  <Input.Password
                    className="rounded-none bg-white"
                    placeholder="Enter Password"
                  />
                </Form.Item>
                <div className="flex text-white justify-end items-center mt-2">
                  <p
                    onClick={handleForgot}
                    className="cursor-pointer font-semibold "
                  >
                    Forgot Password?
                  </p>
                </div>
                <Form.Item
                  className="w-full"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile number",
                    },
                  ]}
                >
                  <Button
                    loading={loading}
                    htmlType="submit"
                    className="w-full mt-2 font-semibold border-none outline-none hover:border-none hover:outline-none bg-green-600 text-white"
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <NavLink to={"/register"}>
              <p className="m-0 font-semibold text-green-700 cursor-pointer">
                Don't have an account?
              </p>
            </NavLink>
            <div className="w-24 h-24 animate-pulse bg-green-600 absolute top-0 right-0 rounded-bl-full"></div>
            <div className="w-24 h-24 bg-red-600 animate-pulse absolute bottom-0 left-0 rounded-tr-full"></div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default Login;
